import React from 'react';
import { useDispatch } from 'react-redux';
import { actionConfigComponentIndexActive } from '../../actions';
import { Chart as ChartComponent } from '../../Chart';
import { PlotInstance as PlotComponent } from '../../Plot';
import { Table as TableComponent } from '../../Table';
import { Card as CardComponent } from '../../Card';
import { ButtonComponent } from '../../Button';
import { MenuComponent } from '../../Menu';
import { Image } from '../../Image';
import { LayoutContainer as Layout } from '..';
import { TextComponent } from '../../Text';
import { HtmlComponent } from '../../Html';
import { ReferenceComponent } from '../../Reference';
import { BigNumber as NumberComponent } from '../../Number';
import { Icon } from '../../Icon';
import MapComponent from '../../Map';
import { AddComponentMenu } from '../../helpers/Settings/add-component-menu';
import { regionHasComponent } from './helpers';
import { Filter as FilterComponent } from '../../Filter';

const Region = (props) => {
  const {
    regionIndex,
    componentIndex,
    dataRow,
    isEmbed,
    cardIndex,
    columnInTable,
    post,
    user,
    spaceComponent,
  } = props;
  const layoutId = post.components[componentIndex].id;
  const component = post.components[componentIndex];
  const editMode = post.edit;
  const userEmail = user.email;
  const dispatch = useDispatch();

  const addActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.add('active');
    }
  };

  const removeActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.remove('active');
    }
  };

  const setComponentActive = (e, index) => {
    e.stopPropagation();
    dispatch(
      actionConfigComponentIndexActive({
        value: index,
      })
    );
  };

  return (
    <div className="layout--region">
      {editMode === userEmail && !regionHasComponent(regionIndex, layoutId, props) && (
        <AddComponentMenu
          activeMenuItems={
            spaceComponent
              ? ['image', 'button', 'text', 'layout']
              : columnInTable
              ? ['chart', 'number', 'text', 'icon', 'layout', 'button', 'plot']
              : [
                  'map',
                  'chart',
                  'table',
                  'card',
                  'number',
                  'text',
                  'html',
                  'icon',
                  'menu',
                  'reference',
                  'layout',
                  'image',
                  'filter',
                  'button',
                  'plot',
                ]
          }
          regionIndex={regionIndex}
          layoutId={layoutId}
          spaceComponent={spaceComponent}
        />
      )}
      {post.components.map((item, index) => (
        <React.Fragment key={index}>
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'chart' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <ChartComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'plot' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <PlotComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'map' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <MapComponent isEmbed={isEmbed} componentId={item.id} index={index} />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'table' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <TableComponent
                  dataRow={dataRow}
                  isEmbed={isEmbed}
                  componentIndex={index}
                  componentId={item.id}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'card' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <CardComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}

          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'text' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <TextComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'reference' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <ReferenceComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'html' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <HtmlComponent
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'number' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <NumberComponent
                  isEmbed={isEmbed}
                  dataRow={dataRow}
                  componentIndex={index}
                  componentId={item.id}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'icon' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <Icon
                  componentId={item.id}
                  cardIndex={cardIndex}
                  dataRow={dataRow}
                  componentIndex={index}
                  isEmbed={isEmbed}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'layout' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <Layout
                  componentId={item.id}
                  isEmbed={isEmbed}
                  dataRow={dataRow}
                  componentIndex={index}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'image' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <Image
                  componentId={item.id}
                  isEmbed={isEmbed}
                  dataRow={dataRow}
                  componentIndex={index}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'button' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <ButtonComponent
                  componentId={item.id}
                  isEmbed={isEmbed}
                  dataRow={dataRow}
                  componentIndex={index}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'menu' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <MenuComponent
                  componentId={item.id}
                  isEmbed={isEmbed}
                  dataRow={dataRow}
                  componentIndex={index}
                />
              </div>
            )}
          {item.region === regionIndex &&
            item.layout === layoutId &&
            item.type === 'filter' &&
            !item.hidden && (
              <div
                onMouseOver={() => addActiveClass(item.id)}
                onMouseOut={() => removeActiveClass(item.id)}
                onClick={(e) => setComponentActive(e, index)}
              >
                <FilterComponent componentId={item.id} componentIndex={index} />
              </div>
            )}
        </React.Fragment>
      ))}
      {!regionHasComponent(regionIndex, layoutId, props) && editMode === userEmail && (
        <div data-layoutId={layoutId} data-regionIndex={regionIndex} className="region-empty"></div>
      )}
    </div>
  );
};

export default Region;
