/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Tabs, Input, Row, Col, message } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionComponentSourcesGet } from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { SOURCES_API } from '../../../../../configs/env';
import { spaceIDSelector } from '../../../../../reducers/selectors/space';
import { userHandleSelector } from '../../../../../reducers/selectors/user';
import { LoadingOutlined } from '@ant-design/icons';
import { SourceItem } from './components/SourceItem';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import moment from 'moment';
const { TabPane } = Tabs;
const { Search } = Input;

export interface SourceInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const SourceInput: React.FC<SourceInputProps> = (props: SourceInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, isMapLayer, parents, disabled, hasGeometries } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const allSources = useSelector((state: any) => state.sources);
  const [value, setValue] = useState('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [publicSources, setPublicSources] = useState<Dictionary[]>([]);
  const [accountSources, setAccountSources] = useState<Dictionary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const spaceId = useSelector(spaceIDSelector);
  const userHandle = useSelector(userHandleSelector);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (!parents) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  useEffect(() => {
    if (modalVisible) {
      const url = spaceId ? `source/space/${spaceId}` : `source/user/account/${userHandle}`;
      const accountService = new AkukoAPIService(SOURCES_API, url);
      const publicService = new AkukoAPIService(SOURCES_API, `source/public`);

      const fetchAccountSources = accountService.list().then((accountSources) => {
        if ((accountSources as Dictionary[])?.length) {
          (accountSources as Dictionary[]).sort(
            (a, b) => (moment(b.updated) as any) - (moment(a.updated) as any)
          );
        }
        setAccountSources(accountSources as Dictionary[]);
        dispatch(actionComponentSourcesGet(accountSources));
      });

      const fetchPublicSources = publicService.list().then((publicSources) => {
        if ((publicSources as Dictionary[])?.length) {
          (publicSources as Dictionary[]).sort(
            (a, b) => (moment(b.updated) as any) - (moment(a.updated) as any)
          );
        }
        setPublicSources(publicSources as Dictionary[]);
        dispatch(actionComponentSourcesGet(publicSources));
      });

      Promise.all([fetchAccountSources, fetchPublicSources])
        .catch((error) => {
          message.error(error?.message || ERROR_GENERIC);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [modalVisible, spaceId, userHandle]);

  return (
    <Row>
      <Col sm={6}>
        <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Button
        className="source-input-btn"
        disabled={disabled}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        <span className="source-input-btn-label">
          {post.sources[value]?.name || 'Select Source'}
        </span>
      </Button>
      </Col>
      <Modal
        className="source-select-modal"
        footer={false}
        width={'70%'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Tabs>
          <TabPane tab="My Sources" key="private">
            <div className="source-list-search">
              <Search
                placeholder="Search Sources..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              <div className="source-list">
                <Row gutter={10}>
                  {accountSources.map(
                    (item: Dictionary, index: number) =>
                      item.public === false &&
                      item.name?.toLowerCase()?.includes(search.toLowerCase()) && (
                        <SourceItem
                          key={index}
                          componentIndex={componentIndex}
                          childIndex={childIndex}
                          itemIndex={itemIndex}
                          parents={parents}
                          property={property}
                          value={value}
                          isMapLayer={isMapLayer}
                          setModalVisible={(value: boolean) => {
                            setModalVisible(value);
                          }}
                          item={item}
                        />
                      )
                  )}
                </Row>
              </div>
            )}
          </TabPane>
          <TabPane tab="Public Sources" key="public">
            <div className="source-list-search">
              <Search
                placeholder="Search Sources..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {isLoading ? (
              <LoadingOutlined />
            ) : (
              <div className="source-list">
                <Row gutter={10}>
                  {publicSources.map(
                    (item: Dictionary, index: number) =>
                      item.public === true &&
                      item.name?.toLowerCase()?.includes(search.toLowerCase()) && (
                        <SourceItem
                          key={index}
                          componentIndex={componentIndex}
                          childIndex={childIndex}
                          itemIndex={itemIndex}
                          parents={parents}
                          property={property}
                          value={value}
                          isMapLayer={isMapLayer}
                          setModalVisible={(value: boolean) => {
                            setModalVisible(value);
                          }}
                          item={item}
                        />
                      )
                  )}
                </Row>
              </div>
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </Row>
  );
};

export { SourceInput };
