import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { getComponentById } from '../../../reducers/selectors/post';
import { PlotComponent } from '../../../configs/component-types';
import { useMemo } from 'react';
import { GenericComponent } from '../Component';
import { useFetchMarkData } from '../hooks';
import { CHART_DEFAULT_QUERY_LIMIT } from '../../../configs/constants';
import { BarChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { getContextRow } from '../helpers';
import * as Plot from '@observablehq/plot';
import { cleanupGlobalPlotProps, marksBuilder, projectionGetter } from './Helpers/helpers';

/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface PlotProps {
  componentIndex: number; // index of the chart component
  componentId: string; // id of the chart component
  filterByValue?: string; // value to filter by against the data
  dataRow?: Dictionary;
  cardIndex?: number; // card index
  isEmbed?: boolean;
}
const PlotInstance: React.FC<PlotProps> = React.memo((props: PlotProps) => {
  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  const { componentIndex, dataRow, cardIndex, isEmbed, componentId } = props;

  const component = useSelector((state) => {
    const plot = selectComponentById(state as any, { componentId });

    if (!plot) {
      return undefined;
    }
    return plot as PlotComponent;
  });

  const [isLoading, data] = useFetchMarkData(
    componentId,
    component?.limit ? Number(component.limit) : CHART_DEFAULT_QUERY_LIMIT,
    cardIndex
  );

  console.log('========data', data)
  const chartRef = useRef<HTMLInputElement>(null);
  const legendRef = useRef<HTMLInputElement>(null);
  const { filteredData } = getContextRow(dataRow, data, component, true);

  const genericComponentProps = {
    componentIndex,
    componentId,
    dataRow,
    isChart: true,
    drawerTitle: 'Chart',
    isEmbed: isEmbed,
  };

  useEffect(() => {
    if (component) {
      const {
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        scheme,
        backgroundColor,
        legend,
        xAxis,
        xInterval,
        xLabel,
        xTickFormat,
        xTickRotate,
        yAxis,
        yInterval,
        yLabel,
        yTickFormat,
        yTickRotate,
        projection,
        lat,
        lon,
        radius,
        setCentre,
        schemeClass,
        schemeType,
      } = component;
      // add frame option for charts
      const globalPlotOptions = {
        style: {
          width: '100%',
          // fontSize: '14',
          background: backgroundColor,
        },
        color: {
          legend: legend,
          scheme: scheme || undefined,
          type: schemeType,
          n: schemeClass,
        },
        projection: projectionGetter(projection, { lat, lon, radius }, setCentre),
        x: {
          axis: xAxis,
          interval: xInterval || undefined,
          label: xLabel || undefined,
          tickFormat: xTickFormat || undefined,
          tickRotate: xTickRotate,
        },
        y: {
          axis: yAxis,
          interval: yInterval || undefined,
          label: yLabel || undefined,
          tickFormat: yTickFormat || undefined,
          tickRotate: yTickRotate,
        },
        // fy: {
        //   label: null,
        //   padding: 0.3, // y ? 0.3 : 0,
        // },
        height: height || undefined,
        marginTop: marginTop || undefined,
        marginRight: marginRight || undefined,
        marginLeft: marginLeft || undefined,
        marginBottom: marginBottom || undefined,
      };
      const plot = Plot.plot({
        ...cleanupGlobalPlotProps(globalPlotOptions),
        marks: marksBuilder(component?.marks, filteredData),
      });
      if (chartRef.current) {
        chartRef.current.append(plot);
      }
      if (legendRef.current) {
        legendRef.current.append(
          Plot.legend({ width: 320, color: { type: 'ordinal', domain: [10, 1000] } })
        );
      }
      if (plot) {
        return () => plot.remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, JSON.stringify(component)]);

  if (!component) {
    return null;
  }
  return (
    <GenericComponent {...genericComponentProps}>
      {isLoading && (
        <div className="component-loader">
          <LoadingOutlined />
        </div>
      )}
      {component?.marks?.length > 0 && !isLoading && <div ref={chartRef}></div>}
      {!component?.marks?.length && !isLoading && (
        <div className="component-empty">
          <BarChartOutlined />
        </div>
      )}
    </GenericComponent>
  );
});

PlotInstance.displayName = 'chart';
export { PlotInstance };
