import * as Plot from '@observablehq/plot';
import { MarkConfig } from '../../../../configs/component-types';
import { Dictionary } from '@onaio/utils';
import { RenderableMark } from '@observablehq/plot';
import * as d3 from 'd3';

export const fillGenerator = (
  mark: MarkConfig
): string | ((feature: Dictionary) => void) | undefined => {
  if (mark.fillProperty && mark.useFillChannel) {
    if (mark.markType === 'geo') {
      return (d: any) => d.properties[`${mark.fillProperty}`];
    }
    return `${mark.cube}.${mark.fillProperty}`;
  } else if (mark.useCustomFillChannel && mark.fillValue) {
    return mark.fillValue;
  } else if (mark.fillColor) {
    return mark.fillColor;
  }
  return undefined;
};

export const markGetter = (mark: MarkConfig, data: Dictionary): RenderableMark | null => {
  switch (mark.markType) {
    case 'areaX':
      return Plot.areaX(data[mark.id] || [], {
        y: mark.y ? `${`${mark.cube}.${mark.y}`}` : undefined,
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fill: fillGenerator(mark),
        offset: mark.offset,
        interval: mark.interval || undefined,
      });

    case 'areaY':
      return Plot.areaY(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
        offset: mark.offset,
        interval: mark.interval || undefined,
      });

    case 'rectX':
      return Plot.rectX(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
        insetTop: mark.insetTop,
        insetBottom: mark.insetBottom,
      });
    case 'rectY':
      return Plot.rectY(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
        insetTop: mark.insetTop,
        insetBottom: mark.insetBottom,
      });

    case 'barX':
      return Plot.barX(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
        insetTop: mark.insetTop,
        insetBottom: mark.insetBottom,
      });

    case 'barY':
      return Plot.barY(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
        insetTop: mark.insetTop,
        insetBottom: mark.insetBottom,
      });

    case 'lineX':
      return Plot.lineX(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        stroke:
          mark.stroke && mark.useStrokeChannel
            ? `${mark.cube}.${mark.stroke}`
            : mark.strokeColor
            ? mark.strokeColor
            : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
      });

    case 'lineY':
      return Plot.lineY(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        stroke:
          mark.stroke && mark.useStrokeChannel
            ? `${mark.cube}.${mark.stroke}`
            : mark.strokeColor
            ? mark.strokeColor
            : undefined,
        fill: fillGenerator(mark),
        interval: mark.interval || undefined,
      });

    case 'line':
      return Plot.lineX(data[mark.id] || [], {
        x: mark.x && mark.y ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y && mark.x ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        stroke:
          mark.stroke && mark.useStrokeChannel
            ? `${mark.cube}.${mark.stroke}`
            : mark.strokeColor
            ? mark.strokeColor
            : undefined,
        strokeWidth: mark.strokeWidth,
        strokeOpacity: mark.strokeOpacity,
        fill: fillGenerator(mark),
        fillOpacity: mark.fillOpacity,

        interval: mark.interval || undefined,
      });

    case 'cell':
      return Plot.cell(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
      });

    case 'text':
      return Plot.text(data[mark.id] || [], {
        x: mark.x ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
        dy: mark.dy,
        dx: mark.dx,
      });

    case 'dot':
      return Plot.dot(data[mark.id] || [], {
        x: mark.x && mark.y ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y && mark.x ? `${mark.cube}.${mark.y}` : undefined,
        fx: mark.fx ? `${mark.cube}.${mark.fx}` : undefined,
        fy: mark.fy ? `${mark.cube}.${mark.fy}` : undefined,
        fill: fillGenerator(mark),
        fillOpacity: mark.fillOpacity,
        stroke:
          mark.stroke && mark.useStrokeChannel
            ? `${mark.cube}.${mark.stroke}`
            : mark.strokeColor
            ? mark.strokeColor
            : undefined,
        strokeWidth: mark.strokeWidth,
        strokeOpacity: mark.strokeOpacity,
        r:
          mark.radiusProperty && mark.useRadiusProperty
            ? `${mark.cube}.${mark.radiusProperty}`
            : mark.radius
            ? mark.radius
            : undefined,
        symbol: mark.symbolType,
      });
    case 'geo':
      return Plot.geo(data[mark.id] || [], {
        fill: fillGenerator(mark),
        strokeOpacity: mark.strokeOpacity || 0.2,
        stroke: mark.strokeColor || undefined,
      });

    case 'density':
      return Plot.density(data[mark.id] || [], {
        x: mark.x && mark.y ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y && mark.x ? `${mark.cube}.${mark.y}` : undefined,
        bandwidth: mark.bandWidth,
        fill: fillGenerator(mark),
      });
    case 'voronoiMesh':
      return Plot.voronoiMesh(data[mark.id] || [], {
        x: mark.x && mark.y ? `${mark.cube}.${mark.x}` : undefined,
        y: mark.y && mark.x ? `${mark.cube}.${mark.y}` : undefined,
      });
    default:
      return null;
  }
};

export const marksBuilder = (
  marks: MarkConfig[] | undefined,
  data: Dictionary[]
): (RenderableMark | null)[] => {
  if (marks?.length) {
    const marksList: (RenderableMark | null)[] = [];
    marks.forEach((mark: MarkConfig) => {
      if (mark.visible && mark.markType) {
        marksList.push(markGetter(mark, data));
      }
    });
    return marksList;
  }
  return [];
};

export const cleanupGlobalPlotProps = (plotConfig: Dictionary): Dictionary | undefined => {
  let hasDefinedProps = false;

  for (const key in plotConfig) {
    if (typeof plotConfig[key] === 'object' && plotConfig[key] !== null) {
      // If the value is an object, recurse.
      plotConfig[key] = cleanupGlobalPlotProps(plotConfig[key]);
    }

    if (plotConfig[key] !== undefined) {
      // If the current property is not undefined, set flag to true
      hasDefinedProps = true;
    } else {
      // If the value is undefined, delete the key-value pair.
      delete plotConfig[key];
    }
  }

  // Return undefined if no properties are defined
  return hasDefinedProps ? plotConfig : undefined;
};

export const projectionGetter = (
  projection: string | undefined,
  centre: Dictionary,
  setCetre?: boolean
): Dictionary | string | undefined => {
  const { lat, lon, radius } = centre;

  if (lat && lon && radius && setCetre) {
    const domain = d3
      .geoCircle()
      .center([lat, lon])
      .radius(radius || 10)
      .precision(2)();
    return {
      type: projection || 'mercator',
      domain: domain,
      // inset: 100,
      clip: false,
    };
  } else {
    return projection || undefined;
  }
};
