import { GroupFieldProps } from '../types';

export const plot: GroupFieldProps = {
  groups: ['Settings', 'Style', 'Margin', 'Marks', 'X Plot Settings', 'Y Plot Settings', 'Height'],
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Settings',
      placeHolder: 'Plot Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Settings',
      placeHolder: 'Plot Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Settings',
      placeHolder: 'Plot Identifier',
    },

    {
      type: 'switch',
      label: 'Show Legend',
      property: 'legend',
      group: 'Style',
      cols: 24,
    },

    {
      type: 'select',
      label: 'Scheme',
      property: 'scheme',
      group: 'Style',
      cols: 24,
      options: [
        { value: 'blues', label: 'Blues' },
        { value: 'greens', label: 'Greens' },
        { value: 'reds', label: 'Reds' },
        { value: 'purples', label: 'Purples' },
        { value: 'oranges', label: 'Oranges' },
        { value: 'greys', label: 'Greys' },
      ],
      placeHolder: 'Choose Scheme',
    },

    {
      type: 'select',
      label: 'Type',
      property: 'schemeType',
      group: 'Style',
      cols: 24,
      options: [
        { value: 'quantize', label: 'Quantize' },
        { value: 'quantile', label: 'Quantile' },
        { value: 'diverging', label: 'Diverging' },
        { value: 'linear', label: 'Linear' },
        { value: 'ordinal', label: 'Ordinal' },
      ],
      placeHolder: 'Choose Scheme',
      dependsOn: [
        {
          property: 'scheme',
          value: '*',
        },
      ],
    },
    {
      type: 'slider',
      label: 'Class number',
      property: 'schemeClass',
      min: 0,
      max: 15,
      step: 1,
      group: 'Style',
      dependsOn: [
        {
          property: 'scheme',
          value: '*',
        },
        {
          property: 'type',
          value: '*',
        },
      ],
    },

    {
      type: 'select',
      label: 'Projection',
      property: 'projection',
      group: 'Style',
      cols: 24,
      options: [
        { value: 'mercator', label: 'Mercator' },
        { value: 'albers', label: 'Albers' },
        { value: 'albers-usa', label: 'Albers Usa' },
        { value: 'azimuthal-equal-area', label: 'Azimuthal Equal Area' },
        { value: 'equal-earth', label: 'Equal earth' },
        { value: 'azimuthal-equidistant', label: 'Azimuthal Equidsitant' },
        { value: 'equirectangular', label: 'Equirectangular' },
        { value: 'orthographic', label: 'Orthographic' },
      ],
      placeHolder: 'Select Projection (for geo marks only)',
    },

    {
      type: 'checkbox',
      label: 'Set centre',
      property: 'setCentre',
      group: 'Style',
      cols: 24,
    },

    {
      type: 'slider',
      label: 'Latitude',
      property: 'lat',
      min: -90,
      max: 90,
      step: 0.01,
      group: 'Style',
      dependsOn: [
        {
          property: 'setCentre',
          value: true,
        },
      ],
    },
    {
      type: 'slider',
      label: 'Longitude',
      property: 'lon',
      min: -180,
      max: 180,
      step: 0.01,
      group: 'Style',
      dependsOn: [
        {
          property: 'setCentre',
          value: true,
        },
      ],
    },
    {
      type: 'slider',
      label: 'Zoom',
      property: 'radius',
      min: 10,
      max: 50,
      group: 'Style',
      dependsOn: [
        {
          property: 'setCentre',
          value: true,
        },
      ],
    },
    {
      type: 'color',
      label: 'Background Color',
      property: 'backgroundColor',
      group: 'Style',
      placeHolder: 'Plot Background Color',
    },

    {
      type: 'slider',
      label: 'Height',
      property: 'height',
      min: 100,
      max: 1000,
      group: 'Height',
    },
    {
      type: 'text',
      label: 'X Axis',
      property: 'xAxis',
      group: 'X Plot Settings',
      placeHolder: 'Show X Axis',
    },
    {
      type: 'text',
      label: 'X Interval',
      property: 'xInterval',
      group: 'X Plot Settings',
      placeHolder: 'Enter X interval',
    },
    {
      type: 'text',
      label: 'X Label',
      property: 'xLabel',
      group: 'X Plot Settings',
      placeHolder: 'Enter X Label',
    },
    {
      type: 'text',
      label: 'X tick format',
      labelLink: 'https://github.com/d3/d3-time-format',
      property: 'xTickFormat',
      group: 'X Plot Settings',
      placeHolder: 'Enter X Tick Format',
    },
    {
      type: 'text',
      label: 'X tick rotate',
      property: 'xTickRotate',
      group: 'X Plot Settings',
      placeHolder: 'Enter X Tick Rotate',
    },
    {
      type: 'text',
      label: 'Y Axis',
      property: 'yAxis',
      group: 'Y Plot Settings',
      placeHolder: 'Show Y Axis',
    },
    {
      type: 'text',
      label: 'Y Interval',
      property: 'yInterval',
      group: 'Y Plot Settings',
      placeHolder: 'Enter Y interval',
    },

    {
      type: 'text',
      label: 'Y Label',
      property: 'yLabel',
      group: 'Y Plot Settings',
      placeHolder: 'Enter Y Label',
    },
    {
      type: 'text',
      label: 'Y tick format',
      property: 'yTickFormat',
      group: 'Y Plot Settings',
      placeHolder: 'Enter Y Tick Format',
    },
    {
      type: 'text',
      label: 'Y tick rotate',
      property: 'yTickRotate',
      group: 'Y Plot Settings',
      placeHolder: 'Enter Y Tick Rotate',
    },

    {
      type: 'slider',
      label: 'Margin top',
      property: 'marginTop',
      min: 0,
      max: 300,
      group: 'Margin',
    },
    {
      type: 'slider',
      label: 'Margin bottom',
      property: 'marginBottom',
      min: 0,
      max: 300,
      group: 'Margin',
    },
    {
      type: 'slider',
      label: 'Margin Right',
      property: 'marginRight',
      min: 0,
      max: 300,
      group: 'Margin',
    },
    {
      type: 'slider',
      label: 'Margin Left',
      property: 'marginLeft',
      min: 0,
      max: 300,
      group: 'Margin',
    },

    {
      type: 'array',
      property: 'marks',
      label: '',
      item: 'Marks',
      itemLabel: 'Marks',
      collapseProperty: 'markName',
      collapseParent: 'Marks',
      group: 'Marks',
      settings: [
        {
          label: 'Source',
          collapse: true,
          dependsOn: [],
        },
        {
          label: 'Channels/Constants',
          collapse: true,
          dependsOn: [],
        },
        {
          label: 'Sort',
          collapse: true,
          dependsOn: [],
        },
        {
          label: 'Filters',
          dependsOn: [],
          collapse: true,
        },
      ],
      groups: ['Source', 'Channels/Constants', 'Sort', 'Filters'],
      defaultValue: {
        key: '',
        source: '',
        visible: true,
      },
      fields: [
        // {
        //   type: 'switch',
        //   property: 'visible',
        //   label: 'Visible',
        //   parents: ['marks'],
        //   group: 'Visibility',
        // },
        // {
        //   type: 'mark',
        //   property: 'markGroup',
        //   label: 'Mark group',
        //   parents: ['marks'],
        //   group: 'group',
        //   placeHolder: 'Set Mark Group',
        // },
        {
          type: 'text',
          property: 'markName',
          label: 'Mark name',
          parents: ['marks'],
          group: 'Source',
          placeHolder: 'Enter Mark Name',
        },
        {
          type: 'select',
          property: 'markType',
          label: 'Mark Type',
          parents: ['marks'],
          options: [
            { value: 'areaX', label: 'Area X' },
            { value: 'areaY', label: 'Area Y' },
            { value: 'rectX', label: 'Rect X' },
            { value: 'rectY', label: 'Rect Y' },
            { value: 'barX', label: 'Bar X' },
            { value: 'barY', label: 'Bar Y' },
            { value: 'lineX', label: 'Line X' },
            { value: 'lineY', label: 'Line Y' },
            { value: 'line', label: 'Line' },
            { value: 'ruleX', label: 'Rule X' },
            { value: 'ruleY', label: 'Rule Y' },
            { value: 'cell', label: 'Cell' },
            { value: 'text', label: 'Text' },
            { value: 'dot', label: 'Dot' },
            { value: 'geo', label: 'Geo' },
            { value: 'voronoiMesh', label: 'Voronoimesh' },
            { value: 'density', label: 'Density' },
          ],
          group: 'Source',
          placeHolder: 'Enter Mark Name',
        },
        {
          type: 'source',
          property: 'source',
          label: '',
          parents: ['marks'],
          //   isMapLayer: true,
          // hasGeometries: true,
          group: 'Source',
        },
        {
          type: 'geometry',
          label: 'Mark geometry',
          property: 'geometryIndex',
          parents: ['marks'],
          group: 'Source',
          dependsOn: [
            {
              property: 'source',
              value: '*',
            },
            {
              property: 'markType',
              value: 'geo',
            },
          ],
          placeHolder: 'Set Mark Geometry',
        },
        {
          type: 'dimension',
          property: 'x',
          showMeasures: true,
          showDimensions: true,
          label: 'Mark X Axis',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter X Axis',
          dependsOn: [
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'dimension',
          property: 'y',
          showMeasures: true,
          showDimensions: true,
          label: 'Mark Y Axis',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter Y Axis',
          dependsOn: [
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'dimension',
          property: 'fy',
          showMeasures: true,
          showDimensions: true,
          label: 'Faceting on Y',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter Y Faceting Property',
          dependsOn: [
            {
              property: 'markType',
              value: ['areaY', 'barY', 'lineY', 'rectY'],
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'dimension',
          property: 'fx',
          showMeasures: true,
          showDimensions: true,
          label: 'Faceting on X',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter X Faceting Property',
          dependsOn: [
            {
              property: 'markType',
              value: ['areaX', 'barX', 'lineX', 'rectX'],
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Fill color based on property',
          property: 'useFillChannel',
          parents: ['marks'],
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Enter custom fill value',
          property: 'useCustomFillChannel',
          parents: ['marks'],
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'useFillChannel',
              value: ['!', true],
            },
          ],
        },

        {
          type: 'text',
          property: 'fillValue',
          label: 'Set Fill Value',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Set Fill Value',
          dependsOn: [
            {
              property: 'useCustomFillChannel',
              value: true,
            },
          ],
        },

        {
          type: 'color',
          label: 'Fill Color',
          property: 'fillColor',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Choose Fill Color',
          dependsOn: [
            {
              property: 'useFillChannel',
              value: '!',
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },

        {
          type: 'dimension',
          property: 'fillProperty',
          showMeasures: true,
          showDimensions: true,
          label: 'Fill  property',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter Y Axis',
          dependsOn: [
            {
              property: 'useFillChannel',
              value: true,
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'slider',
          label: 'Fill Opacity',
          property: 'fillOpacity',
          parents: ['marks'],
          min: 0.1,
          step: 0.1,
          max: 1,
          group: 'Channels/Constants',
        },
        {
          type: 'checkbox',
          label: 'Stroke color based on property',
          property: 'useStrokeChannel',
          parents: ['marks'],
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'color',
          label: 'Stroke Color',
          property: 'strokeColor',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Choose Stroke Color',
          dependsOn: [
            {
              property: 'useStrokeChannel',
              value: '!',
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'dimension',
          property: 'stroke',
          showMeasures: true,
          showDimensions: true,
          label: 'Stroke  property',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter Y Axis',
          dependsOn: [
            {
              property: 'useStrokeChannel',
              value: true,
            },
            {
              property: 'markType',
              value: '*',
            },
          ],
        },
        {
          type: 'slider',
          label: 'Stroke Opacity',
          parents: ['marks'],
          property: 'strokeOpacity',
          min: 0.1,
          max: 1,
          step: 0.1,
          group: 'Channels/Constants',
        },
        {
          type: 'slider',
          label: 'Stroke Width',
          parents: ['marks'],
          property: 'strokeWidth',
          min: 0,
          max: 8,
          step: 1,
          group: 'Channels/Constants',
        },

        {
          type: 'checkbox',
          label: 'Radius based on property',
          property: 'useRadiusProperty',
          parents: ['marks'],
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'markType',
              value: ['dot'],
            },
          ],
        },

        {
          type: 'dimension',
          property: 'radiusProperty',
          showMeasures: true,
          showDimensions: true,
          label: 'Radius  property',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Enter radius property',
          dependsOn: [
            {
              property: 'useRadiusProperty',
              value: true,
            },
          ],
        },

        {
          type: 'slider',
          label: 'Radius',
          parents: ['marks'],
          property: 'radius',
          min: 1,
          max: 100,
          step: 1,
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'useRadiusProperty',
              value: '!',
            },
            {
              property: 'markType',
              value: ['dot'],
            },
          ],
        },

        {
          type: 'select',
          property: 'symbolType',
          label: 'Select Symbol',
          parents: ['marks'],
          options: [
            { value: 'square', label: 'Square' },
            { value: 'circle', label: 'Circle' },
            { value: 'star', label: 'Star' },
            { value: 'triangle', label: 'Triangle' },
            { value: 'cross', label: 'Cross' },
          ],
          group: 'Channels/Constants',
          placeHolder: 'Enter symbol type',
          dependsOn: [
            {
              property: 'markType',
              value: ['dot'],
            },
          ],
        },
        {
          type: 'slider',
          label: 'Bandwidth',
          parents: ['marks'],
          property: 'bandWidth',
          min: 0,
          max: 50,
          step: 1,
          group: 'Channels/Constants',
          dependsOn: [
            {
              property: 'markType',
              value: ['density'],
            },
          ],
        },

        {
          type: 'text',
          property: 'interval',
          label: 'Set Interval',
          parents: ['marks'],
          group: 'Channels/Constants',
          placeHolder: 'Set Interval',
          dependsOn: [
            {
              property: 'markType',
              value: '*',
            },
          ],
        },

        {
          type: 'array',
          label: '',
          property: 'orders',
          itemLabel: 'Order',
          parents: ['marks'],
          group: 'Sort',
          groups: ['Sort'],
          collapseProperty: '1',
          collapseParent: 'orders',
          settings: [
            {
              label: 'Sort',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          defaultValue: {
            property: '',
            sortOrder: '',
          },
          fields: [
            {
              type: 'dimension',
              property: 'property',
              showMeasures: true,
              showDimensions: true,
              label: 'Sort Property',
              parents: ['marks', 'orders'],
              group: 'Sort',
              placeHolder: 'Enter Sort Properties',
            },
            {
              type: 'select',
              label: 'Sort Order',
              property: 'sortOrder',
              group: 'Sort',
              parents: ['marks', 'orders'],
              options: [
                { value: 'asc', label: 'Acending' },
                { value: 'desc', label: 'Descending' },
              ],
            },
          ],
        },
        {
          type: 'array',
          label: '',
          property: 'filters',
          itemLabel: 'Filter',
          parents: ['marks'],
          group: 'Filters',
          groups: ['Filters'],
          collapseProperty: '1',
          collapseParent: 'filters',
          settings: [
            {
              label: 'Filters',
              dependsOn: [],
              showCollapse: false,
            },
            {
              label: 'Options',
              dependsOn: [],
              collapse: false,
            },
          ],
          defaultValueType: 'array',
          defaultValue: {
            0: '==',
            1: '',
            2: [],
            3: [],
            4: [],
            inherit: false,
          },
          fields: [
            {
              type: 'dimension',
              label: 'Property',
              property: '1',
              group: 'Filters',
              showMeasures: true,
              showDimensions: true,
              parents: ['marks', 'filters'],
              placeHolder: 'Set Field to Filter by',
              setDataType: true,
              dataTypeKey: 'dataType',
              setDataSource: true,
              dataSourceKey: 'dataSource',
            },
            {
              type: 'select',
              label: 'Operator',
              property: '0',
              group: 'Filters',
              parents: ['marks', 'filters'],
              options: [
                { value: '==', label: 'equal to' },
                { value: '!=', label: 'not equal to' },
                { value: '>', label: 'greater than' },
                { value: '>=', label: 'greater than or equal to' },
                { value: 'contains', label: 'contains' },
                { value: '<=', label: 'less than or equal to' },
                { value: '<', label: 'less than' },
                { value: 'isNull', label: 'is null' },
                { value: 'notNull', label: 'not null' },
              ],
              dependsOn: [
                {
                  property: 'dataType',
                  value: ['!', 'time'],
                },
              ],
            },
            {
              type: 'select',
              label: 'Operator',
              group: 'Filter',
              property: '0',
              parents: ['marks', 'filters'],
              defaultValue: 'afterDate',
              options: [
                { value: 'beforeDate', label: 'is before' },
                { value: 'afterDate', label: 'is after' },
                { value: 'inDateRange', label: 'in date range' },
                { value: 'isNull', label: 'is null' },
                { value: 'notNull', label: 'not null' },
                { value: '==', label: 'equal to' },
                { value: '!=', label: 'not equal to' },
                { value: '>', label: 'greater than' },
                { value: '>=', label: 'greater than or equal to' },
                { value: 'contains', label: 'contains' },
                { value: '<=', label: 'less than or equal to' },
                { value: '<', label: 'less than' },
              ],
              dependsOn: [
                {
                  property: 'dataType',
                  value: ['time'],
                },
                {
                  property: 'dataSource',
                  value: ['postgres'],
                },
              ],
            },
            {
              type: 'select',
              label: 'Operator',
              group: 'Filters',
              property: '0',
              parents: ['marks', 'filters'],
              defaultValue: 'afterDate',
              options: [
                { value: 'beforeDate', label: 'is before' },
                { value: 'afterDate', label: 'is after' },
                { value: 'inDateRange', label: 'in date range' },
                { value: 'isNull', label: 'is null' },
                { value: 'notNull', label: 'not null' },
              ],
              dependsOn: [
                {
                  property: 'dataType',
                  value: ['time'],
                },
                {
                  property: 'dataSource',
                  value: ['!', 'postgres'],
                },
              ],
            },
            {
              type: 'dimension-value',
              label: 'Value',
              property: '2',
              group: 'Filters',
              parents: ['marks', 'filters'],
              showMeasures: true,
              showDimensions: true,
              dependsOn: [
                {
                  property: '0',
                  value: ['==', '!='],
                },
                {
                  property: 'showTextField',
                  value: '!',
                },
              ],
              placeHolder: 'Set value to Filter by',
            },

            {
              type: 'dimension-value',
              label: 'Value',
              group: 'Filters',
              property: '2',
              parents: ['marks', 'filters'],
              showMeasures: true,
              showDimensions: true,
              dependsOn: [
                {
                  property: '0',
                  value: ['beforeDate', 'afterDate'],
                },
                {
                  property: 'showTextField',
                  value: '!',
                },
              ],
              placeHolder: 'Set value to Filter by',
            },
            {
              type: 'dimension-value',
              label: 'Greater than or equal to',
              group: 'Filters',
              property: '3',
              parents: ['marks', 'filters'],
              showMeasures: true,
              showDimensions: true,
              dependsOn: [
                {
                  property: '0',
                  value: ['notInDateRange', 'inDateRange'],
                },
                {
                  property: 'showTextField',
                  value: '!',
                },
              ],
              placeHolder: 'Set value to Filter by',
            },
            {
              type: 'dimension-value',
              label: 'Less than or equal to',
              group: 'Filters',
              property: '4',
              parents: ['marks', 'filters'],
              showMeasures: true,
              showDimensions: true,
              dependsOn: [
                {
                  property: '0',
                  value: ['notInDateRange', 'inDateRange'],
                },
                {
                  property: 'showTextField',
                  value: '!',
                },
              ],
              placeHolder: 'Set value to Filter by',
            },
            {
              type: 'text',
              label: 'Value',
              group: 'Filters',
              property: '2',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: '0',
                  value: ['>', '<', '<=', '>=', 'contains'],
                },
              ],
              placeHolder: 'Set value to Filter by',
            },
            {
              type: 'text',
              label: 'Value',
              group: 'Filters',
              property: '2',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: '0',
                  value: ['!', '>', '<', '<=', '>=', 'isNull', 'notNull'],
                },
                {
                  property: 'showTextField',
                  value: [true],
                },
              ],
              placeHolder: 'Set value to Filter by',
            },
            {
              type: 'checkbox',
              label: 'Inherit filter value',
              property: 'inherit',
              group: 'Options',
              cols: 24,
              parents: ['marks', 'filters'],
            },
            {
              type: 'checkbox',
              label: 'Expose this filter',
              group: 'Options',
              cols: 23,
              property: 'expose',
              parents: ['marks', 'filters'],
            },
            {
              type: 'checkbox',
              label: 'Allow multiple values',
              group: 'Options',
              cols: 23,
              property: 'multiple',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: 'dataType',
                  value: ['!', 'time'],
                },
              ],
            },
            {
              type: 'checkbox',
              label: 'Use slider input',
              advanced: true,
              group: 'Options',
              cols: 24,
              property: 'slider',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: 'showTextField',
                  value: '!',
                },
              ],
            },
            {
              type: 'checkbox',
              label: 'Use Date picker',
              group: 'Options',
              advanced: true,
              cols: 24,
              property: 'datePicker',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: 'showTextField',
                  value: '!',
                },
                {
                  property: 'slider',
                  value: '!',
                },
              ],
            },
            {
              type: 'checkbox',
              label: 'Disable input clear',
              advanced: true,
              cols: 24,
              group: 'Options',
              dependsOn: [
                {
                  property: 'expose',
                  value: [true],
                },
              ],
              property: 'disableClear',
              parents: ['marks', 'filters'],
            },

            {
              type: 'checkbox',
              label: 'Use text input',
              advanced: true,
              group: 'Options',
              cols: 24,
              property: 'showTextField',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: '0',
                  value: ['!', 'isNull', 'notNull', '>', '<', '<=', '>='],
                },
                {
                  property: 'slider',
                  value: '!',
                },
              ],
            },
            {
              type: 'checkbox',
              label: 'Show Actual Date Format',
              advanced: true,
              group: 'Options',
              cols: 24,
              property: 'actualDateFormat',
              parents: ['marks', 'filters'],
              dependsOn: [
                {
                  property: 'dataType',
                  value: ['time'],
                },
              ],
            },

            {
              type: 'text',
              label: 'Filter name',
              group: 'Options',
              property: 'name',
              parents: ['marks', 'filters'],
              dependsOn: [{ property: 'expose', value: true }],
              placeHolder: 'Filter Name',
            },
            {
              type: 'select',
              label: 'Input width',
              group: 'Options',
              property: 'cols',
              parents: ['marks', 'filters'],
              dependsOn: [{ property: 'expose', value: true }],
              placeHolder: 'Small',
              options: [
                { label: 'Small', value: '8' },
                { label: 'Medium', value: '12' },
                { label: 'Large', value: '24' },
              ],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Enable Cascade',
          property: 'cascade',
          advanced: true,
          group: 'Filters',
          parents: ['marks'],
        },
      ],
    },
  ],
};
