import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs,
  Button,
  Collapse,
  Form,
  Input,
  Select,
  TimePicker,
  message,
  InputNumber,
  Popconfirm,
} from 'antd';

import { AkukoAPIService } from '../../../../services/serviceClass';
import {
  SOURCES_API,
  TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL,
} from '../../../../configs/env';
import { Dictionary } from '@onaio/utils';
import { actionSourceSave } from '../../actions';
import {
  Source,
  sourceRefreshStatusTypes,
  SourceRefreshScheduleRequestPayload,
} from '../../../../configs/component-types';
import {
  startAsycJobForCreatingSourceRefreshSchedule,
  startAsycJobForUpdatingSourceRefreshSchedule,
} from '../../helpers/helpers';
import { triggerAsycSourceOperation } from '../../components/SourceWebsocketsUpdatesHelpers';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ERROR_GENERIC } from '../../../../configs/constants';

const { Panel } = Collapse;

const { Option } = Select;

export interface SourceRefreshSchedulerProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

type PickerType = 'every' | 'daily' | 'weekly';

const SourceRefreshScheduler: React.FC<SourceRefreshSchedulerProps> = (
  props: SourceRefreshSchedulerProps
) => {
  const dispatch = useDispatch();
  const asyncTaskName = 'Source refresh schedule';
  const [type, setType] = useState<PickerType>();
  const source = useSelector((store: Dictionary) => store.source);
  const [form] = Form.useForm();
  const { sourceActionSaveCreator } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<
    { stage: sourceRefreshStatusTypes; data: Dictionary[]; errors: Dictionary[] } | Dictionary
  >({});
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const sourceHasSchedule = source?.config?.refresh_metadata !== undefined;

  const getSourceFromApiAndUpdateRedux = async () => {
    const sourceGetService = new AkukoAPIService(SOURCES_API, 'source');
    const res = await sourceGetService.read(`${source?.uuid}`);
    const sourceObj = res as Source;
    if (sourceActionSaveCreator) {
      dispatch(
        /* @ts-ignore */
        sourceActionSaveCreator({
          ...sourceObj,
        })
      );
    }
  };

  useEffect(() => {
    if (progress?.stage === sourceRefreshStatusTypes.DONE) {
      (async () => {
        await getSourceFromApiAndUpdateRedux();
      })();
      setProgress({});
      setLoading(false);
      message.success('Source scheduled created successfully');
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleSourceRefreshScheduleCreation = (payload: SourceRefreshScheduleRequestPayload) => {
    setLoading(true);
    triggerAsycSourceOperation({
      asynFunc: sourceHasSchedule
        ? startAsycJobForUpdatingSourceRefreshSchedule
        : startAsycJobForCreatingSourceRefreshSchedule,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL,
    });
  };

  const onFinish = async (values: Dictionary) => {
    try {
      let refresh_config = {};
      const type = values?.type as PickerType;

      switch (type) {
        case 'every':
          refresh_config = {
            intervals: [{ every: `${values?.schedule_every}h` }],
          };
          break;
        case 'daily':
          refresh_config = {
            calendars: [
              {
                hour: values?.schedule_daily?.$H,
                minute: values?.schedule_daily?.$m,
                comment: `${values?.description !== undefined ? values?.description : ''}`,
              },
            ],
          };
          break;
        case 'weekly':
          refresh_config = {
            calendars: [
              {
                dayOfWeek:
                  Array.isArray(values.day) === true
                    ? values.day.map((i: any) => i.toUpperCase())
                    : `${values?.day?.toUpperCase()}`,
                hour: values?.schedule_weekly?.$H,
                minute: values?.schedule_weekly?.$m,
                comment: `${values?.description !== undefined ? values?.description : ''}`,
              },
            ],
          };
          break;
        default:
          break;
      }
      handleSourceRefreshScheduleCreation({
        sourceId: source?.uuid,
        payload: {
          refresh_config,
          refresh_type: type,
        },
      });
    } catch (err) {
      const error = new Error(err as string);
      message.error(`Failed to schedule task, ${error?.message}`);
    }
  };

  const tabs = [
    {
      key: '1',
      label: 'New Schedule',
      children: (
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="source-refresh"
          key={type}
        >
          <Form.Item
            name="type"
            label="Schedule Type"
            rules={[{ required: true, message: 'Please select a schedule type' }]}
          >
            <Select value={type} onChange={setType} disabled={loading}>
              <Option value="every">Every</Option>
              <Option value="daily">Daily</Option>
              <Option value="weekly">Weekly</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="day"
            label="Days of the Week"
            style={{ display: type === 'weekly' ? 'block' : 'none' }}
            rules={[
              { required: type === 'weekly' ? true : false, message: 'Please enter a schedule' },
            ]}
          >
            <Select mode={'multiple'} disabled={loading}>
              <Option value="Monday">Monday</Option>
              <Option value="Tuesday">Tuesday</Option>
              <Option value="Wednesday">Wednesday</Option>
              <Option value="Thursday">Thursday</Option>
              <Option value="Friday">Friday</Option>
              <Option value="Saturday">Saturday</Option>
              <Option value="Sunday">Sunday</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="schedule_daily"
            key={type}
            label="Schedule Daily Time in UTC"
            style={{ display: type === 'daily' ? 'block' : 'none' }}
            rules={[{ required: type === 'daily', message: 'Please enter a schedule' }]}
          >
            <TimePicker key={type} disabled={loading} />
          </Form.Item>
          <Form.Item
            name="schedule_weekly"
            key={type}
            label="Schedule Weekly Time in UTC"
            style={{ display: type === 'weekly' ? 'block' : 'none' }}
            rules={[{ required: type === 'weekly', message: 'Please enter a schedule' }]}
          >
            <TimePicker key={type} disabled={loading} />
          </Form.Item>
          <Form.Item
            name="schedule_every"
            key={type}
            label="Schedule in Hours"
            style={{ display: type === 'every' ? 'block' : 'none' }}
            rules={[{ required: type === 'every', message: 'Please enter a schedule' }]}
          >
            <InputNumber min={1} key={type} addonAfter="hrs" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: false }]}
            style={{ display: type !== 'every' ? 'block' : 'none' }}
          >
            <Input.TextArea placeholder="Describe schedule" disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading && <LoadingOutlined />} {loading ? 'Saving...' : 'Save'}
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  if (source?.config?.refresh_metadata) {
    const refreshType = source?.config?.refresh_metadata?.refresh_type;
    const lastRefreshAt = source?.config?.refresh_metadata?.last_refreshed_at;
    let scheduleDescription;
    const calendars = source?.config?.refresh_metadata?.refresh_config?.calendars;
    if (calendars) {
      scheduleDescription = calendars[0]?.comment !== '' ? calendars[0]?.comment : undefined;
    }
    const calendarHours = calendars && calendars[0]?.hour;
    const calendarMinutes = calendars && calendars[0]?.minute;
    const dayOfWeek = calendars && calendars[0]?.dayOfWeek;
    const intervals = source?.config?.refresh_metadata?.refresh_config?.intervals;
    const everyHours = intervals && intervals[0]?.every;
    let time;
    if (calendarHours && (calendarMinutes || calendarMinutes === 0)) {
      time = moment.utc(`${calendarHours}:${calendarMinutes}`, 'HH:mm').local().format('HH:mm');
    }

    tabs.push({
      key: '2',
      label: 'Existing Refresh Schedule',
      children: (
        <>
          {refreshType === 'every' ? (
            <div className="input-field">
              <label>Every</label>
              {`${everyHours === '1h' ? `${everyHours}r` : `${everyHours}rs`}`}
            </div>
          ) : null}
          {refreshType === 'daily' ? (
            <div className="input-field">
              <label>Daily at</label>
              {`${time} `}(Local Time)
            </div>
          ) : null}
          {refreshType === 'weekly' ? (
            <>
              <div className="input-field">
                <label>Weekly at</label>
                {`${time} `}(Local Time)
              </div>
              <div className="input-field">
                <label>Days of the week</label>
                {`${dayOfWeek}`}
              </div>
            </>
          ) : null}
          {scheduleDescription !== undefined ? (
            <div className="input-field">
              <label>Description</label>
              {`${scheduleDescription}`}
            </div>
          ) : null}
          {lastRefreshAt !== undefined ? (
            <div className="input-field">
              <label>Last refreshed at</label>
              {`${moment(lastRefreshAt).format('MMM DD YYYY - HH:mm')} (Local Time)`}
            </div>
          ) : null}
          <div className="input-field">
            <Popconfirm
              title="Are you sure you want to delete this schedule?"
              onConfirm={() => {
                setShowDeleteSpinner(true);
                const service = new AkukoAPIService(
                  SOURCES_API,
                  `source/schedules/${source?.uuid}`
                );
                service
                  .delete()
                  .then(() => {
                    (async () => {
                      await getSourceFromApiAndUpdateRedux();
                    })();
                    message.success('Source refresh schedule deleted');
                    setShowDeleteSpinner(false);
                  })
                  .catch((err) => {
                    if (err.message) {
                      message.error(err.message);
                    } else {
                      message.error(ERROR_GENERIC);
                    }
                    setShowDeleteSpinner(false);
                  });
              }}
            >
              <Button danger disabled={showDeleteSpinner}>
                {showDeleteSpinner && <LoadingOutlined />}{' '}
                {showDeleteSpinner ? 'Deleting...' : 'Delete'}
              </Button>
            </Popconfirm>
          </div>
        </>
      ),
    });
  }

  return (
    <Collapse>
      <Panel key={1} header="Refresh Schedule">
        <Tabs defaultActiveKey="1" items={tabs} />
      </Panel>
    </Collapse>
  );
};

SourceRefreshScheduler.defaultProps = defaultProps;
export { SourceRefreshScheduler };
